import React from 'react'
import { ModalConsumer } from './modal-context'

export const ModalRoot = () => (
  <ModalConsumer>
    {({ modals, hideModal, showModal }) => {
      return (
        Object.entries(modals) || []
      ).map(([key, { component: Component, props }]) => (
        <Component
          {...props}
          key={key}
          showModal={(component, modalProps) =>
            showModal(component, modalProps)
          }
          hideModal={() => hideModal(key)}
        />
      ))
    }}
  </ModalConsumer>
)
