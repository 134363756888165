import React from 'react'
import cx from 'classnames'
import styles from './button.module.css'

export const Button2 = ({
  children,
  size,
  hasShadow = true,
  isBlock,
  color,
  colorDisabled,
  handleClick,
  disabled = false,
  className,
  variant = 'basic',
  buttonType = 'button',
  fullWidth,
  ...rest
}) => {
  return (
    <button
      type={buttonType}
      className={cx(
        styles.button,
        {
          [styles.buttomSm]: size === 'sm',
          [styles.noShadow]: !hasShadow,
          [styles.buttonBlock]: isBlock,
          [styles.buttonBasic]: variant === 'basic',
          [styles.buttonOutline]: variant === 'outline',
          [styles.buttonPrimary]: color === 'primary',
          [styles.buttonSecondary]: color === 'secondary',
          [styles.fullWidth]: fullWidth,
          [styles.buttonDisabledGrey]: colorDisabled === 'grey',
        },
        className
      )}
      onClick={handleClick}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  )
}
