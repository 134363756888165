import scrollToElement from 'scroll-to-element';

export function scroller(target, offset) {
  scrollToElement(target, {
    offset,
  })
}

export function handleMenuLinkClick(link, lang) {
  if (typeof window !== "undefined" && link.link.includes("#")) {
    const [anchorPath, anchor] = link.link.split("#")
    if (
      window.location.pathname === `${anchorPath}` ||
      (lang === "en" && window.location.pathname === `${anchorPath}${lang}/`)
    ) {
      // e && e.preventDefault()
      scroller(`#${anchor}`, 0)
    }
  }
}

export function getCookie(name) {
  let matches;
  if (typeof document !== `undefined`) {
    matches = document.cookie.match(
      new RegExp(
        "(?:^|; )" +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
        "=([^;]*)"
      )
    )
  }

  return matches ? decodeURIComponent(matches[1]) : undefined
}

export function setCookie(name, value, options = {}) {
  const expDays = 30;

  let date = new Date();
  date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));

  options = {
    path: '/',
    expires: date.toUTCString(),
    // при необходимости добавьте другие значения по умолчанию
    ...options
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
}

export function getUTM() {
  const UTM_from_local_storage = typeof window !== 'undefined' && JSON.parse(window.localStorage.getItem("UTM"))
  let UTM_SOURCE = ""
  let UTM_MEDIUM = ""
  let UTM_CAMPAIGN = ""
  let UTM_TERM = ""
  let UTM_CONTENT = ""
  if (UTM_from_local_storage !== null) {
    UTM_SOURCE = UTM_from_local_storage.source
    UTM_MEDIUM = UTM_from_local_storage.medium
    UTM_CAMPAIGN = UTM_from_local_storage.campaign
    UTM_TERM = UTM_from_local_storage.term
    UTM_CONTENT = UTM_from_local_storage.content
  } else {
    let urlString = document.location.href
    let url = new URL(urlString)
    UTM_SOURCE = url.searchParams.get("utm_source")
    UTM_MEDIUM = url.searchParams.get("utm_medium")
    UTM_CAMPAIGN = url.searchParams.get("utm_campaign")
    UTM_TERM = url.searchParams.get("utm_term")
    UTM_CONTENT = url.searchParams.get("utm_content")
  }
  return {
    UTM_SOURCE,
    UTM_MEDIUM,
    UTM_CAMPAIGN,
    UTM_TERM,
    UTM_CONTENT,
  }
}

export function getReferrer() {
  const referrer_from_local_storage = JSON.parse(
    localStorage.getItem("referrer")
  )

  if (referrer_from_local_storage !== null) {
    return referrer_from_local_storage
  } else if (document.referrer !== "") {
    return new URL(document.referrer).hostname
  } else {
    return ""
  }
}

export function formatPhoneNumber(number) {
  if (number.charAt(0) === "+") {
    return '00' + number.substring(1);
  } else {
    return '00' + number;
  }
}

export function objectToQueryString(obj) {
  return Object.keys(obj).map(key => key + '=' + obj[key]).join('&');
}

export function removeAfterSpace(text) {
  const spaceIndex = text.indexOf(" ");
  if (spaceIndex !== -1) {
    return text.slice(0, spaceIndex).toLowerCase();
  } else {
    return text.toLowerCase();
  }
}

export function checkCity(city) {
  switch (city) {
    case '643e59b953518500442b2158':
      return true;
    case '6450c5c4447b90b44371794b':
      return true;
    case '6466498d5e95c90679ffb628':
      return true
    case '6450c5c4447b90f76471794a':
      return true
    case '64411b5479a776993dad643a':
      return true;
    case '64411b5479a7767d42ad6449':
      return true;
    case 'prague':
      return true
    case 'prague-area':
      return true
    case 'kladno':
      return true
    case 'plzen':
      return true
    case 'liberec':
      return false
    case 'mlada-boleslav':
      return false
    case 'jablonec-nad-nisou':
      return false
    case 'pardubice':
      return false
    case 'hradec-kralove':
      return false
    case 'brno':
      return false
    default:
      return false
  }
}

export function sendCityValues(cityId) {
  switch (cityId) {
    case '6450c5c4447b9098d471794c':
      return ['17:00', '22:00'];
    case 'liberec':
      return ['17:00', '22:00'];
    case '6450c5c4447b9018d1717950':
      return ['16:30', '22:00'];
    case 'hradec-kralove':
      return ['16:30', '22:00'];
    case '6450c5c4447b90802071794f':
      return ['16:30', '22:00'];
    case 'pardubice':
      return ['16:30', '22:00'];
    case 'brno':
      return ['17:00', '22:00']
    case '6450c5c4447b9052a571794e':
      return ['18:00', '22:00'];
    case 'jablonec-nad-nisou':
      return ['18:00', '22:00'];
    case '6450c5c4447b90183171794d':
      return ['16:00', '22:00'];
    case 'mlada-boleslav':
      return ['16:00', '22:00'];
    case '6465df4a449d63655e04edce':
      return ['18:00', '22:00']
    case '64411b5479a776ca0bad644a':
      return ['17:00', '22:00'];
    case '64411b5479a776b8c6ad644e':
      return ['16:30', '22:00'];
    case '64411b5479a776b092ad644d':
      return ['16:30', '22:00'];
    case '64411b5479a776499cad644c':
      return ['18:00', '22:00'];
    case '64411b5479a776f560ad644b':
      return ['16:00', '22:00'];
    default:
      return false
  }
}


export function formatTimeRange(timeRange) {
  const [startTime, endTime] = timeRange;
  const startDate = new Date();
  const endDate = new Date();

  // set the start time for the start date object
  const [startHours, startMinutes] = startTime.split(':');
  startDate.setHours(parseInt(startHours, 10));
  startDate.setMinutes(parseInt(startMinutes, 10));

  // set the start time for the end date object
  const [endHours, endMinutes] = endTime.split(':');
  endDate.setHours(parseInt(endHours, 10));
  endDate.setMinutes(parseInt(endMinutes, 10));

  // format the date strings
  const formattedStartDate = startDate.toISOString();
  const formattedEndDate = endDate.toISOString();
  const data = [formattedStartDate, formattedEndDate]

  return data;
}