import React from 'react'
import { useTranslation } from 'react-i18next'

export const ErrorMessage = () => {
  const { t } = useTranslation()
  return t('forms.formValidationMessage')
}

export const NameErrorMessage = () => {
  const { t } = useTranslation()
  return t('forms.formValidationName')
}

export const ZIPErrorMessage = () => {
  const { t } = useTranslation()
  return t('forms.formValidationICO')
}

export const ICOErrorMessage = () => {
  const { t } = useTranslation()
  return t('forms.formValidationICO')
}

export const DICErrorMessage = () => {
  const { t } = useTranslation()
  return t('forms.formValidationDIC')
}

export const OrganizationNameErrorMessage = () => {
  const { t } = useTranslation()
  return t('forms.formValidationOrganizationName')
}

export const FakturaErrorMessage = () => {
  const { t } = useTranslation()
  return t('forms.formValidationFaktura')
}

export const PhoneErrorMessage = () => {
  const { t } = useTranslation()
  return t('forms.formValidationPhoneMessage')
}

export const EmailErrorMessage = () => {
  const { t } = useTranslation()
  return t('forms.formValidationEmailMessage')
}

export const EmailErrorRequiredMessage = () => {
  const { t } = useTranslation()
  return t('forms.formValidationEmailRequiredMessage')
}

export const NameErrorRequiredMessage = () => {
  const { t } = useTranslation()
  return t('forms.formValidationNameRequiredMessage')
}

export const AddressErrorMessage = () => {
  const { t } = useTranslation()
  return t('forms.formValidationAddressMessage')
}

export const CityErrorMessage = () => {
  const { t } = useTranslation()
  return t('forms.formValidationCityMessage')
}

export const TermsErrorRequiredMessage = () => {
  const { t } = useTranslation()
  return t('forms.formValidationTermsRequiredMessage')
}

export const Terms2ErrorRequiredMessage = () => {
  const { t } = useTranslation()
  return t('forms.formValidationTerms2RequiredMessage')
}
