import cx from 'classnames';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';

import styles from './modal.module.css';

export const Modal2 = React.memo(
  ({ children, closeModal, darkMode = false, className }) => {
    const domEl = document.getElementById("root")

    if (!domEl) return null

    return ReactDOM.createPortal(
      <div className={styles.modalOverlay}>
        <div
          className={cx(styles.modal, { [styles.dark]: darkMode }, className)}
        >
          <div className={styles.modalHeader}>
            <button
              type="button"
              className={styles.modalClose}
              onClick={closeModal}
            >
              <svg
                width="61"
                height="61"
                viewBox="0 0 61 61"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_d)">
                  <circle cx="30.5" cy="26.5" r="20.5" fill="white" />
                </g>
                <g clip-path="url(#clip0)">
                  <path
                    d="M32.1047 27L40.7709 18.3337C41.0761 18.0286 41.0761 17.5339 40.7709 17.2288C40.4658 16.9238 39.9711 16.9237 39.6661 17.2288L30.9997 25.8951L22.3335 17.2288C22.0284 16.9237 21.5337 16.9237 21.2286 17.2288C20.9235 17.534 20.9235 18.0286 21.2286 18.3337L29.8949 27L21.2286 35.6663C20.9235 35.9714 20.9235 36.4661 21.2286 36.7712C21.3811 36.9237 21.5811 37 21.7811 37C21.981 37 22.1809 36.9237 22.3335 36.7712L30.9997 28.1049L39.666 36.7712C39.8186 36.9237 40.0185 37 40.2185 37C40.4184 37 40.6184 36.9237 40.7709 36.7712C41.0761 36.4661 41.0761 35.9714 40.7709 35.6663L32.1047 27Z"
                    fill="#353643"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_d"
                    x="0"
                    y="0"
                    width="61"
                    height="61"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="5" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow"
                      result="shape"
                    />
                  </filter>
                  <clipPath id="clip0">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="translate(21 17)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </div>
          <div className={styles.modalBody}>{children}</div>
        </div>
      </div>,
      domEl
    )
  }
)

export const useModal = () => {
  const [isVisible, setIsVisible] = useState(false)

  const show = () => {
    document.body.style.height = "100vh"
    document.body.style.overflow = "hidden"
    setIsVisible(true)
  }
  const hide = () => {
    document.body.style.height = "initial"
    document.body.style.overflow = "initial"
    setIsVisible(false)
  }

  const RenderModal = ({ children, darkMode, className }) => (
    <>
      {isVisible && (
        <Modal2 closeModal={hide} darkMode={darkMode} className={className}>
          {children}
        </Modal2>
      )}
    </>
  )

  return {
    show,
    hide,
    RenderModal,
  }
}
