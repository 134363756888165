export const OrderFoodDeliveryTimeData = (city = 'praha', date = new Date()) => {
  const dateData = {
    praha: [
      [newDate(date, 18), newDate(date, 20)],
      [newDate(date, 19), newDate(date, 21)],
      [newDate(date, 20), newDate(date, 22)],
    ],
    prahaArea: [
      [newDate(date, 18), newDate(date, 20)],
      [newDate(date, 19), newDate(date, 21)],
      [newDate(date, 20), newDate(date, 22)],
    ],
    kladno: [
      [newDate(date, 18), newDate(date, 20)],
      [newDate(date, 19), newDate(date, 21)],
      [newDate(date, 20), newDate(date, 22)],
    ],
    brno: [
      [newDate(date, 18), newDate(date, 20)],
      [newDate(date, 19), newDate(date, 21)],
      [newDate(date, 20), newDate(date, 22)],
    ],
    plzeň: [
      [newDate(date, 17), newDate(date, 20)],
      [newDate(date, 18), newDate(date, 21)],
      [newDate(date, 19), newDate(date, 21)],
    ],
    mladá: [
      [newDate(date, 16), newDate(date, 20)],
      [newDate(date, 19), newDate(date, 21)],
      [newDate(date, 20), newDate(date, 22)],
    ],
    liberec: [
      [newDate(date, 17), newDate(date, 20)],
      [newDate(date, 19), newDate(date, 21)],
      [newDate(date, 20), newDate(date, 22)],
    ],
    hradec: [
      [newDate(date, 16.5), newDate(date, 20)],
      [newDate(date, 19), newDate(date, 21)],
      [newDate(date, 20), newDate(date, 22)],
    ],
    between: [
      [newDate(date, 18), newDate(date, 20)],
      [newDate(date, 19), newDate(date, 21)],
      [newDate(date, 20), newDate(date, 22)],
    ],
    pardubice: [
      [newDate(date, 16.5), newDate(date, 20)],
      [newDate(date, 19), newDate(date, 21)],
      [newDate(date, 20), newDate(date, 22)],
    ],
    kladno: [
      [newDate(date, 18), newDate(date, 20)],
      [newDate(date, 19), newDate(date, 21)],
      [newDate(date, 20), newDate(date, 22)],
    ],
    jablonec: [
      [newDate(date, 18), newDate(date, 20)],
      [newDate(date, 19), newDate(date, 21)],
      [newDate(date, 20), newDate(date, 22)],
    ]
  }

  return dateData[city]
}

export const getOrderFoodDeliveryTimeData = (city) => {
  return OrderFoodDeliveryTimeData(city)
}

const newDate = (date, hours = 0) => {
  const newDate = new Date(date)
  const minutes = Math.floor((hours % 1) * 60)
  newDate.setHours(Math.floor(hours), minutes, 0)
  return newDate
}

export const OrderFoodDeliveryPaymentSystemData = {
  PayPal: 1,
  ApplePay: 2,
  Card: 3,
  Bank: 4,
}

export const isOrderFoodDeliveryPaymentPayPal = (value) => {
  return OrderFoodDeliveryPaymentSystemData.PayPal === value
}

export const isOrderFoodDeliveryPaymentApplePay = (value) => {
  return OrderFoodDeliveryPaymentSystemData.ApplePay === value
}

export const isOrderFoodDeliveryPaymentCard = (value) => {
  return OrderFoodDeliveryPaymentSystemData.Card === value
}

export const isOrderFoodDeliveryPaymentBank = (value) => {
  return OrderFoodDeliveryPaymentSystemData.Bank === value
}
