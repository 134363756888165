import React, { createContext, useContext, useState } from 'react'
import { omit } from './helpers'

const ModalContext = createContext({
  modals: {},
  showModal: () => {},
  hideModal: () => {},
})

export const useModalContext = () => {
  return useContext(ModalContext)
}

const initialState = () => ({
  modals: {},
  showModal: () => {},
  hideModal: () => {},
})

export const ModalProvider = (props) => {
  const { children } = props

  const [state, setState] = useState(initialState())

  const showModal = (component, props = {}) => {
    const modalId = new Date().getTime()

    setState({
      ...state,
      modals: {
        ...state.modals,
        [modalId]: {
          component,
          props,
        },
      },
    })
  }

  const hideModal = (modalId) => {
    setState({
      ...state,
      modals: omit(state.modals, modalId),
    })
  }

  return (
    <ModalContext.Provider value={{ ...state, showModal, hideModal }}>
      {children}
    </ModalContext.Provider>
  )
}

export const ModalConsumer = ModalContext.Consumer
