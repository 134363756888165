export const OrderFoodDeliveryType = {
  Address: 'DeliveryAddress',
  Calendar: 'DeliveryCalendar',
  Time: 'DeliveryTime',
  PaymentSystem: 'DeliveryPaymentSystem',
  Price: 'DeliveryPrice',
  Terms1: 'DeliveryTerms1',
  Terms2: 'DeliveryTerms2',
  Promo: 'PromoCode',
  City: 'DeliveryCity'
}
