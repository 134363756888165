import React from "react"
import { Button2 } from "../button2"

import styles from "./prefooter.module.css"

export const Prefooter = () => {
  return (
    <>
      {/*<div className={styles.prefooter}>*/}
      {/*  <h2 className={styles.prefooterTitle}>Staňte se naším partnerem</h2>*/}
      {/*  <p className={styles.prefooterText}>*/}
      {/*    Chcete se stát naším partnerem a vydělávat tak společně s námi?*/}
      {/*  </p>*/}
      {/*  <p className={styles.prefooterText}>Ozvěte se nám.</p>*/}
      {/*  <Button2*/}
      {/*    color="secondary"*/}
      {/*    buttonType="button"*/}
      {/*    className={styles.prefooterButton}*/}
      {/*  >*/}
      {/*    <a href="http://partners.nutritionpro.cz/" target="_blank">*/}
      {/*      Chci se stát partnerem*/}
      {/*    </a>*/}
      {/*  </Button2>*/}
      {/*</div>*/}
    </>
  )
}
