export const OrderFoodOnlineType = {
  NumberOfPeople: 'NumberOfPeople',
  CustomerGender: 'CustomerGender',
  CustomerMenu: 'CustomerMenu',
  Program: 'Program',
  NumberOfMeals: 'NumberOfMeals',
  NumberOfKcal: 'NumberOfKcal',
  ProgramDuration: 'ProgramDuration',
  WeekMealLength: 'WeekMealLength',
  PriceDiscount: 'PriceDiscount',
  Discount: 'Discount',
  PromoCode: 'PromoCode',
  IsCompanyOrder: 'IsCompanyOrder',
  Exeptions: 'Exeptions',
  ProgramType: 'ProgramType',
  ProgramDaysDuration:"ProgramDays",
  ProgramDurationTime:'ProgramDurationTime'
}

export const OrderNumberOfPeopleType = {
  One: 1,
  Two: 2,
  ThreeOrMore: 3,
  TheCompany: 5,
}

export const OrderCustomerGenderType = {
  Male: 'MALE',
  Female: 'FEMALE',
}

export const OrderCustomerMenuType = {
  Meat: 'MEAT',
  Vege: 'VEGE',
  BEZ_RIB: 'BEZ_RIB'
}

export const OrderProgramType = {
  WeightLossProgram: 'WEIGHT_LOSS_PROGRAM',
  RecruitmentProgram: 'RECRUITMENT_PROGRAM',
  MaintenanceProgram: 'MAINTENANCE_PROGRAM',
  LunchAndDinnerProgram: 'LUNCH_AND_DINNER',
  WeightGainProgram: 'WEIGHT_GAIN_PROGRAM',
  
}

export const OrderProgramDurationType = {
  TwoWeeks: 'TWO_WEEKS',
  OneMonth: 'ONE_MONTH',
  TwoMonth: 'TWO_MONTH',
  ThreeMonth: 'THREE_MONTH',
}
export const OrderProgramDaysType = {
  days5: '5_DAYS',
  days7: '7_DAYS',
  days20: '20_DAYS',
  days30: '30_DAYS',
}

export const OrderProgram = {
  Nutrition: 'Nutriotion',
  NutritionVege: 'Nutriotion Vege',
  NutritionActive: 'Nutriotion Active',
  Office:'Office',
  OfficeVege:'OfficeVege'
}

export const OrderNumberOfMealsType = {
  Breakfast: 'BREAKFAST',
  SnackFirst: 'SNACK_FIRST',
  Lunch: 'LUNCH',
  SnackSecond: 'SNACK_SECOND',
  Dinner: 'DINNER',
}

export const OrderNumberOfMeals = {
  One: 1,
  Two: 2,
  Three: 3,
  Four: 4,
  Five: 5,
}

export const OrderWeekMealLengthType = {
  FiveDays: '5_DAYS',
  SixDays: '6_DAYS',
}
export const OrderCustomerWeekType = {
  Monday: 'MONDAY',
  Tuesday: 'TUESDAY',
  Wednesday: 'WEDNESDAY',
  Thursday: 'THURSDAY',
  Friday: 'FRIDAY',
  Saturday: 'SATURDAY'
}
