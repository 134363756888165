import {
  OrderCustomerGenderType,
  OrderFoodOnlineType,
  OrderCustomerMenuType,
  OrderNumberOfPeopleType,
  OrderProgramDurationType,
  OrderProgramType,
  OrderWeekMealLengthType, OrderProgramDaysType,
} from './order-food-online-type'
import { useOrderFoodContext } from './store'
import {
  findOrderFoodDurationPriceVariants,
  findOrderFoodKcalVariants,
  findOrderFoodPrice,
} from './order-food-online-data'
import { OrderFoodDeliveryType } from './order-food-delivery-type'
import { OrderFoodDeliveryPaymentSystemData } from './order-food-delivery-data'

export const useGetters = () => {
  const [state] = useOrderFoodContext()

  return {
    state,
    // online
    isOrderProgramDuration2Month: isOrderProgramDuration2Month(state),
    isOrderProgramDuration3Month: isOrderProgramDuration3Month(state),
    isOrderProgramDuration1Month: isOrderProgramDuration1Month(state),
    getOrderProgramDurationInDays: getOrderProgramDurationInDays(state),
    isOrderFoodCustomerGenderFemale: isOrderFoodCustomerGenderFemale(state),
    isOrderFoodCustomerGenderMale: isOrderFoodCustomerGenderMale(state),
    isOrderFoodCustomerMenuMeat: isOrderFoodCustomerMenuMeat(state),
    isOrderFoodCustomerMenuVege: isOrderFoodCustomerMenuVege(state),
    isOrderFoodCustomerMenuBezRib: isOrderFoodCustomerMenuBezRib(state),
    isOrderFoodNumberOfPeopleCompany: isOrderFoodNumberOfPeopleCompany(state),
    isOrderFoodProgramLunchAndDinner: isOrderFoodProgramLunchAndDinner(state),
    isOrderProgramDuration2Weeks: isOrderProgramDuration2Weeks(state),
    isOrderProgramDuration5Days: isOrderProgramDuration5Days(state),
    isOrderProgramDuration7Days: isOrderProgramDuration7Days(state),
    isOrderProgramDuration20Days: isOrderProgramDuration20Days(state),
    isOrderProgramDuration30Days: isOrderProgramDuration30Days(state),
    isOrderFoodProgramWeightLoss: isOrderFoodProgramWeightLoss(state),
    isOrderFoodNumberOfPeopleThreeOrMore: isOrderFoodNumberOfPeopleThreeOrMore(
      state
    ),
    isOrderFoodNumberOfPeopleOne: isOrderFoodNumberOfPeopleOne(state),
    isOrderFoodNumberOfPeopleTwo: isOrderFoodNumberOfPeopleTwo(state),
    isOrderFoodProgramMaintenance: isOrderFoodProgramMaintenance(state),
    isOrderFoodProgramWeightGain: isOrderFoodProgramWeightGain(state),
    isOrderFoodWeekMeal5DaysLength: isOrderFoodWeekMeal5DaysLength(state),
    isOrderFoodWeekMeal6DaysLength: isOrderFoodWeekMeal6DaysLength(state),
    isOrderFoodDeliveryFree: isOrderFoodDeliveryFree(state),
    getOrderProgramDuration: getOrderProgramDuration(state),
    getOrderExeptions: getOrderExeptions(state),
    getOrderFoodWeekMealLength: getOrderFoodWeekMealLength(state),
    getOrderFoodNumberOfMeals: getOrderFoodNumberOfMeals(state),
    getOrderFoodNumberOfPeople: getOrderFoodNumberOfPeople(state),
    getOrderFoodCustomerGender: getOrderFoodCustomerGender(state),
    getOrderFoodProgram: getOrderFoodProgram(state),
    getOrderProgramType:getOrderProgramType(state),
    getOrderNumberOfKcal: getOrderNumberOfKcal(state),
    getOrderProgram: getOrderProgram(state),
    getOrderNumberOfKJ: getOrderNumberOfKJ(state),
    calculateKcalToKJ: value => calculateKcalToKJ(value),
    getOrderNumberOfKcalVariants: getOrderNumberOfKcalVariants(state),
    getOrderFoodPrice: getOrderFoodPrice(state),
    getOrderFoodTotalPrice: getOrderFoodTotalPrice(state),
    getOrderdProgramDurationTime: getOrderdProgramDurationTime(state),
    getOrderFoodDiscount: getOrderFoodDiscount(state),
    // getOrderFoodDiscountPrice: getOrderFoodDiscountPrice(state),
    getOrderFoodPriceDiscount: getOrderFoodPriceDiscount(state),
    getOrderFoodPriceByDays: getOrderFoodPriceByDays(state),
    getOrderFoodPriceDiscountDay: getOrderFoodPriceDiscountDay(state),
    getOrderFoodPriceDiscountDays: getOrderFoodPriceDiscountDays(state),
    getOrderFoodPriceDiscountByDays: getOrderFoodPriceDiscountByDays(state),
    getOrderFoodDurationPriceVariants: getOrderFoodDurationPriceVariants(state),
    getOrderFoodDurationPriceVariantsPerDay: getOrderFoodDurationPriceVariantsPerDay(
      state
    ),
    getOrderFoodPriceByDaysWithoutDPH: getOrderFoodPriceByDaysWithoutDPH(state),
    getOrderFoodDPH: getOrderFoodDPH(state),
    getOrderFoodPromoCode: getOrderFoodPromoCode(state),
    // contact-information
    //
    // delivery
    getOrderFoodDeliveryPrice: getOrderFoodDeliveryPrice(state),
    getOrderFoodDeliveryPriceByDays: getOrderFoodDeliveryPriceByDays(state),
    getOrderFoodDeliveryCalendar: getOrderFoodDeliveryCalendar(state),
    getOrderFoodDeliveryTime: getOrderFoodDeliveryTime(state),
    getOrderFoodDeliveryTimeFormatted: getOrderFoodDeliveryTimeFormatted(state),
    getOrderFoodDeliveryPaymentSystem: getOrderFoodDeliveryPaymentSystem(state),
    getOrderDeliveryCity: getOrderFoodDeliveryCity(state),
    isOrderFoodDeliveryPaymentSelected: isOrderFoodDeliveryPaymentSelected(
      state
    ),
    isOrderFoodDeliveryPaymentPayPal: isOrderFoodDeliveryPaymentPayPal(state),
    isOrderFoodDeliveryPaymentApplePay: isOrderFoodDeliveryPaymentApplePay(
      state
    ),
    isOrderFoodDeliveryPaymentCard: isOrderFoodDeliveryPaymentCard(state),
    isOrderFoodDeliveryPaymentBank: isOrderFoodDeliveryPaymentBank(state),
    isOrderFoodDeliveryTerms1: isOrderFoodDeliveryTerms1(state),
    isOrderFoodDeliveryTerms2: isOrderFoodDeliveryTerms2(state),

    isOrderFoodDeliveryValid: isOrderFoodDeliveryValid(state),
    isCompanyOrder: isCompanyOrder(state),
    getOrderFoodDurationPriceVariantsDiscount: getOrderFoodDurationPriceVariantsDiscount(
      state
    ),
  }
}

// online
export const getOrderFoodNumberOfPeople = state => {
  return state?.[OrderFoodOnlineType.NumberOfPeople]
}

export const isOrderFoodNumberOfPeopleOne = state => {
  return getOrderFoodNumberOfPeople(state) === OrderNumberOfPeopleType.One
}

export const getOrderProgram = state => {
  return state?.[OrderFoodOnlineType.Program]
}
export const getOrderProgramType = state=>{
  return state?.[OrderFoodOnlineType.ProgramType]

}

export const isOrderFoodNumberOfPeopleTwo = state => {
  return getOrderFoodNumberOfPeople(state) === OrderNumberOfPeopleType.Two
}

export const isOrderFoodNumberOfPeopleThreeOrMore = state => {
  return (
    getOrderFoodNumberOfPeople(state) === OrderNumberOfPeopleType.ThreeOrMore
  )
}

export const isOrderFoodNumberOfPeopleCompany = state => {
  return (
    getOrderFoodNumberOfPeople(state) === OrderNumberOfPeopleType.TheCompany
  )
}

export const getOrderFoodCustomerGender = state => {
  return state?.[OrderFoodOnlineType.CustomerGender]
}

const isOrderFoodCustomerGenderMale = state => {
  return getOrderFoodCustomerGender(state) === OrderCustomerGenderType.Male
}

const isOrderFoodCustomerGenderFemale = state => {
  return getOrderFoodCustomerGender(state) === OrderCustomerGenderType.Female
}

export const getOrderFoodCustomerMenu = state => {
  return state?.[OrderFoodOnlineType.CustomerMenu]
}

const isOrderFoodCustomerMenuMeat = state => {
  return getOrderFoodCustomerMenu(state) === OrderCustomerMenuType.Meat
}

const isOrderFoodCustomerMenuVege = state => {
  return getOrderFoodCustomerMenu(state) === OrderCustomerMenuType.Vege
}

const isOrderFoodCustomerMenuBezRib = state => {
  return getOrderFoodCustomerMenu(state) === OrderCustomerMenuType.BEZ_RIB
}

export const getOrderFoodProgram = state => {
  return state?.[OrderFoodOnlineType.Program]
}

export const isOrderFoodProgramWeightLoss = state => {
  return getOrderFoodProgram(state) === OrderProgramType.WeightLossProgram
}

export const isOrderFoodProgramWeightGain = state => {
  return getOrderFoodProgram(state) === OrderProgramType.WeightGainProgram
}

export const isOrderFoodProgramMaintenance = state => {
  return getOrderFoodProgram(state) === OrderProgramType.MaintenanceProgram
}

export const isOrderFoodProgramLunchAndDinner = state => {
  return getOrderFoodProgram(state) === OrderProgramType.LunchAndDinnerProgram
}

export const getOrderProgramDuration = state => {

  return state?.[OrderFoodOnlineType.ProgramDuration]
}
export const getOrderProgramDaysDuration = state => {
  return state?.[OrderFoodOnlineType.ProgramDaysDuration]
}

export const getOrderExeptions = state => {
  return state?.[OrderFoodOnlineType.Exeptions]
}

export const isOrderProgramDuration2Weeks = state => {
  return getOrderProgramDuration(state) === OrderProgramDurationType.TwoWeeks
}

export const isOrderProgramDuration5Days = state => {
  return getOrderProgramDaysDuration(state) === OrderProgramDaysType.days5
}

export const isOrderProgramDuration7Days = state => {
  return getOrderProgramDaysDuration(state) === OrderProgramDaysType.days7
}

export const isOrderProgramDuration20Days = state => {
  return getOrderProgramDaysDuration(state) === OrderProgramDaysType.days20
}

export const isOrderProgramDuration30Days = state => {
  return getOrderProgramDaysDuration(state) === OrderProgramDaysType.days30
}

export const isOrderProgramDuration1Month = state => {
  return getOrderProgramDuration(state) === OrderProgramDurationType.OneMonth
}

export const isOrderProgramDuration2Month = state => {
  return getOrderProgramDuration(state) === OrderProgramDurationType.TwoMonth
}

export const isOrderProgramDuration3Month = state => {
  return getOrderProgramDuration(state) === OrderProgramDurationType.ThreeMonth
}

export const getOrderFoodNumberOfMeals = state => {
  return state?.[OrderFoodOnlineType.NumberOfMeals] || null
}

export const getOrderFoodWeekMealLength = state => {
  return state?.[OrderFoodOnlineType.WeekMealLength]
}


export const getOrderdProgramDurationTime = state => {
  return state?.[OrderFoodOnlineType.ProgramDurationTime]
}
const isOrderFoodWeekMeal5DaysLength = state => {
  return getOrderFoodWeekMealLength(state) === OrderWeekMealLengthType.FiveDays
}

const isOrderFoodWeekMeal6DaysLength = state => {
  return getOrderFoodWeekMealLength(state) === OrderWeekMealLengthType.SixDays
}

export const getOrderNumberOfKcal = state => {
  const defaultKcalVariants = getOrderNumberOfKcalVariants(state)

  const [defaultKcal] = defaultKcalVariants
  const kcal = state?.[OrderFoodOnlineType.NumberOfKcal]

  if (kcal && defaultKcalVariants.includes(kcal)) {
    return kcal
  }

  return defaultKcal
}

const getOrderNumberOfKJ = state => {
  const [defaultKcal] = getOrderNumberOfKcalVariants(state)
  const kcal = state?.[OrderFoodOnlineType.NumberOfKcal] || defaultKcal
  return calculateKcalToKJ(kcal)
}

const calculateKcalToKJ = kcal => {
  const kj = 4.184
  return Math.round(kcal * kj)
}

const getOrderNumberOfKcalVariants = state => {
  const data = [
    getOrderFoodCustomerGender(state),
    getOrderFoodProgram(state),
    getOrderFoodNumberOfMeals(state),
  ]

  return findOrderFoodKcalVariants(data)
}

const getOrderFoodPriceByDays = state => {
  return getOrderFoodPrice(state) * getOrderProgramDurationInDays(state)
}

// discount in store after promo is applyed
const getOrderFoodDiscount = state => state?.[OrderFoodOnlineType.Discount]

// totalPrice in store after promo is applyed
const getOrderFoodPriceDiscount = state => {
  return state?.[OrderFoodOnlineType.PriceDiscount]
}

const getOrderFoodPriceDiscountDay = state => {
  return getOrderFoodPriceDiscount(state) / getOrderProgramDurationInDays(state)
}

const getOrderFoodPriceDiscountDays = state => getOrderFoodPriceDiscount(state)

const getOrderFoodPriceDiscountByDays = state => {
  const discount = !!getOrderFoodPriceDiscount(state)

  return discount
    ? getOrderFoodPriceDiscountDays(state)
    : getOrderFoodPrice(state) * getOrderProgramDurationInDays(state)
}

const getOrderProgramDurationInDays = state => {
  if (isOrderProgramDuration2Weeks(state)) {
    return getOrderFoodDuration2Weeks(state)
  }
  if (isOrderProgramDuration1Month(state)) {
    return getOrderFoodDuration1Month(state)
  }
  if (isOrderProgramDuration2Month(state)) {
    return getOrderFoodDuration2Month(state)
  }
  if (isOrderProgramDuration3Month(state)) {
    return getOrderFoodDuration3Month(state)
  }
  return 0
}

const getOrderFoodPrice = state => {
  return findOrderFoodPrice([
    getOrderFoodCustomerGender(state),
    getOrderFoodProgram(state),
    getOrderFoodNumberOfMeals(state),
    getOrderProgramDuration(state),
    getOrderNumberOfKcal(state),
  ])
}

const getOrderFoodTotalPrice = state => {
  const deliveryTotalPrice = Number(getOrderFoodDeliveryPriceByDays(state)) || 0
  const discountTotalPrice = Number(getOrderFoodPriceDiscount(state))
  const basicOrderFoodTotalPrice = Number(getOrderFoodPriceByDays(state))

  const priceInclDiscountAndDelivery =
    discountTotalPrice > 0
      ? discountTotalPrice + deliveryTotalPrice
      : basicOrderFoodTotalPrice + deliveryTotalPrice

  return priceInclDiscountAndDelivery.toFixed()
}

// incl non-free delivery case
const getOrderFoodPriceByDaysWithoutDPH = state => {
  const deliveryFree = isOrderFoodDeliveryFree(state)
  return deliveryFree
    ? Number(
      getOrderFoodPriceDiscountByDays(state) - getOrderFoodDPH(state)
    ).toFixed()
    : Number(
      (getOrderFoodPriceDiscountByDays(state) +
        getOrderFoodDeliveryPrice(state)) /
      1.15
    ).toFixed()
}

// DPH = 15%
const getOrderFoodDPH = state => {
  const deliveryFree = isOrderFoodDeliveryFree(state)

  return deliveryFree
    ? Number((getOrderFoodPriceDiscountByDays(state) / 100) * 15).toFixed()
    : Number(
      ((getOrderFoodPriceDiscountByDays(state) +
        getOrderFoodDeliveryPriceByDays(state)) /
        100) *
      15
    ).toFixed()
}

const isCompanyOrder = state => state?.[OrderFoodOnlineType.IsCompanyOrder]

const getOrderFoodDurationPriceVariantsPerDay = state => {
  const [
    twoWeekPrice,
    monthPrice,
    twoMonthPrice,
    threeMonthPrice,
  ] = findOrderFoodDurationPriceVariants([
    getOrderFoodCustomerGender(state),
    getOrderFoodProgram(state),
    getOrderFoodNumberOfMeals(state),
    getOrderNumberOfKcal(state),
  ])

  return {
    twoWeekPrice,
    monthPrice,
    twoMonthPrice,
    threeMonthPrice,
  }
}

const getOrderFoodDurationPriceVariants = state => {
  const [
    twoWeekPrice,
    monthPrice,
    twoMonthPrice,
    threeMonthPrice,
  ] = findOrderFoodDurationPriceVariants([
    getOrderFoodCustomerGender(state),
    getOrderFoodProgram(state),
    getOrderFoodNumberOfMeals(state),
    getOrderNumberOfKcal(state),
  ])

  return {
    twoWeekPrice: twoWeekPrice * getOrderFoodDuration2Weeks(state),
    monthPrice: monthPrice * getOrderFoodDuration1Month(state),
    twoMonthPrice: twoMonthPrice * getOrderFoodDuration2Month(state),
    threeMonthPrice: threeMonthPrice * getOrderFoodDuration3Month(state),
  }
}

const getOrderFoodDurationPriceVariantsDiscount = state => {
  const [
    twoWeekPrice,
    monthPrice,
    twoMonthPrice,
    threeMonthPrice,
  ] = findOrderFoodDurationPriceVariants([
    getOrderFoodCustomerGender(state),
    getOrderFoodProgram(state),
    getOrderFoodNumberOfMeals(state),
    getOrderNumberOfKcal(state),
  ])

  return {
    monthPrice: ((monthPrice - twoWeekPrice) / twoWeekPrice) * 100,
    twoMonthPrice: ((twoMonthPrice - twoWeekPrice) / twoWeekPrice) * 100,
    threeMonthPrice: ((threeMonthPrice - twoWeekPrice) / twoWeekPrice) * 100,
  }
}

const getOrderFoodPromoCode = state => {
  return state?.[OrderFoodOnlineType.PromoCode]
}

const getOrderFoodDuration2Weeks = state => {
  return getOrderFoodDays(state) * 2
}

const getOrderFoodDuration1Month = state => {
  return getOrderFoodDays(state) * 4
}

const getOrderFoodDuration2Month = state => {
  return getOrderFoodDays(state) * 4 * 2
}

const getOrderFoodDuration3Month = state => {
  return getOrderFoodDays(state) * 4 * 3
}

const getOrderFoodDays = state => {
  if (isOrderFoodWeekMeal5DaysLength(state)) {
    return 5
  }
  if (isOrderFoodWeekMeal6DaysLength(state)) {
    return 6
  }
  return 0
}

// contact-information

// delivery
export const isOrderFoodDeliveryFree = state =>
  getOrderFoodDeliveryPrice(state) <= 0

export const getOrderFoodDeliveryPrice = state => {
  return state?.[OrderFoodDeliveryType.Price]
}

export const getOrderFoodDeliveryPriceByDays = state => {
  return (
    state?.[OrderFoodDeliveryType.Price] * getOrderProgramDurationInDays(state)
  )
}

export const getOrderFoodDeliveryAddress = state => {
  return state?.[OrderFoodDeliveryType.Address]
}

export const getOrderFoodDeliveryCity = state => {
  return state?.[OrderFoodDeliveryType.City]
}

export const getOrderFoodDeliveryCalendar = state => {
  return state?.[OrderFoodDeliveryType.Calendar]
}

export const getOrderFoodDeliveryTime = state => {
  return state?.[OrderFoodDeliveryType.Time]
}

const getOrderFoodDeliveryTimeFormatted = state => {
  const data = getOrderFoodDeliveryTime(state)
  const [from, to] = data

  if (!from || !to) {
    return undefined
  }

  const fromHours = new Date(from).getHours().toString().padStart(2, '0')
  const fromMinutes = new Date(from).getMinutes().toString().padStart(2, '0')
  const toHours = new Date(to).getHours().toString().padStart(2, '0')
  const toMinutes = new Date(to).getMinutes().toString().padStart(2, '0')

  return `${fromHours}:${fromMinutes} - ${toHours}:${toMinutes}`
}

const getOrderFoodDeliveryPaymentSystem = state => {
  return state?.[OrderFoodDeliveryType.PaymentSystem]
}

const isOrderFoodDeliveryPaymentSelected = state => {
  return !!state?.[OrderFoodDeliveryType.PaymentSystem]
}

const isOrderFoodDeliveryPaymentPayPal = state => {
  return (
    state?.[OrderFoodDeliveryType.PaymentSystem] ===
    OrderFoodDeliveryPaymentSystemData.PayPal
  )
}

const isOrderFoodDeliveryPaymentApplePay = state => {
  return (
    state?.[OrderFoodDeliveryType.PaymentSystem] ===
    OrderFoodDeliveryPaymentSystemData.ApplePay
  )
}

const isOrderFoodDeliveryPaymentCard = state => {
  return (
    state?.[OrderFoodDeliveryType.PaymentSystem] ===
    OrderFoodDeliveryPaymentSystemData.Card
  )
}

const isOrderFoodDeliveryPaymentBank = state => {
  return (
    state?.[OrderFoodDeliveryType.PaymentSystem] ===
    OrderFoodDeliveryPaymentSystemData.Bank
  )
}

const isOrderFoodDeliveryTerms1 = state => {
  return state?.[OrderFoodDeliveryType.Terms1]
}

const isOrderFoodDeliveryTerms2 = state => {
  return state?.[OrderFoodDeliveryType.Terms2]
}

const isOrderFoodDeliveryValid = state => {
  const list = [
    !!getOrderFoodDeliveryCalendar(state),
    !!getOrderFoodDeliveryTime(state),
    isOrderFoodDeliveryPaymentSelected(state),
    isOrderFoodDeliveryTerms1(state),
    isOrderFoodDeliveryTerms2(state),
  ]
  return list.every(x => x === true)
}
