import React, { forwardRef } from 'react';
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import "./phone-input.css";

export const MaskedPhoneInput = forwardRef(
  ({ name, value, error, onChange, isWhite, isLg, ...rest }, ref) => {
    const defaultCountry = window.location.pathname.includes("pl")
      ? "PL"
      : "CZ";

    return (
      <PhoneInput
        ref={ref}
        name={name}
        value={value}
        onChange={onChange}
        country={defaultCountry}
        preferredCountries={["cz", "sk", "de", "pl"]}
        enableAreaCodes={true}
        countryCodeEditable={false}
        error={error}
        containerClass={isWhite ? `white-bg ${isLg ? "lg" : ""}` : ""}
        {...rest}
      />
    );
  }
);