import cx from 'classnames'
import { Link } from 'gatsby'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { handleMenuLinkClick } from '../../helpers'
import { useLangContext } from '../../utils/lang'
import { Button } from '../button'
import { Container } from '../container'
import { Logo } from '../header/logo'
import { LocalizedLink } from '../localized-link'
import styles from './footer.module.css'
import styles2 from '../cookie-agreement-modal/styles.module.css'
import fb from './icons/facebook.svg'
import logo from './icons/footer-logo.svg'
import user from './icons/icon-user.svg'
import instagram from './icons/instagram.svg'
import twitter from './icons/twitter.svg'
import mastercard from './icons/master-card.svg'
import maestro from './icons/maestro.svg'
import visa from './icons/visa.svg'
import gopay from './icons/go-pay.svg'
import { Prefooter } from '../prefooter-v2'
import { Button2 } from '../button2'
import { useModal } from '../modal2'

export const Footer2 = () => {
  const { t } = useTranslation()
  const { lang } = useLangContext()
  const isHomepage =
    window.location.pathname === '/' || window.location.pathname === '/en/'
  const isOnlineForm = window.location.href.indexOf('order-food-online') > -1
  const { show, hide, RenderModal } = useModal()
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const link ='/order-food-online'

  return (
    <footer
      className={styles.footer}
      style={
        isOnlineForm
          ? {
            padding: '50px 0',
            marginTop: `${isHomepage || isOnlineForm ? 0 : ''}`,
          }
          : {}
      }
    >
      {/*<RenderModal className={styles2.cookieModal}>*/}
      {/*  <h3 className={styles2.title}>{t('cookieModal.title')}</h3>*/}
      {/*  <p className={styles2.p}>*/}
      {/*    {t('cookieModal.text')}{' '}*/}
      {/*    <LocalizedLink*/}
      {/*      to="/pravidla_pouzivani_cookies.pdf"*/}
      {/*      target="_blank"*/}
      {/*      rel="noopener norefferer"*/}
      {/*    >*/}
      {/*      {t('cookieModal.link')}*/}
      {/*    </LocalizedLink>*/}
      {/*  </p>*/}
      {/*  <div className={styles2.btnsBlock}>*/}
      {/*    <Button2 color="secondary" className={styles2.btn} handleClick={hide}>*/}
      {/*      {t('cookieModal.btn1')}*/}
      {/*    </Button2>*/}
      {/*    <Button2 color="secondary" className={styles2.btn} handleClick={hide}>*/}
      {/*      {t('cookieModal.btn2')}*/}
      {/*    </Button2>*/}
      {/*  </div>*/}
      {/*</RenderModal>*/}
      {!isOnlineForm && <Prefooter />}
      <Container isWide={true}>
        <div className={styles.footerCols}>
          <div className={styles.footerCol}>
            <LocalizedLink to="/">
              <img src={logo} alt="logo" />
            </LocalizedLink>
            <div className={cx(styles.socials)}>
              <a
                href="https://www.facebook.com/nutritionpropl/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={fb} alt="icon" />
              </a>
              <a
                href="https://www.instagram.com/nutritionpro.pl/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={instagram} alt="icon" />
              </a>
            </div>
            <div>
              <div>
                <a
                  className={styles.link}
                  href="https://nutritionpro.pl/regulations/
                "
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Regulamin
                </a>
              </div>
              <div>
                <a
                  className={styles.link}
                  href="https://nutritionpro.pl/personal-data/
                "
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Polityka prywatności
                </a>
              </div>
            </div>
          </div>
          <div className={styles.footerCol}>
            <div className={cx(styles.footerMenu, styles.hideXS)}>
              <h4 className={styles.footerColTitle}>
                {t('home.footerMenuLinks')}
              </h4>
              <Button
                type="unstyled"
                handleClick={() =>
                  handleMenuLinkClick({ link: '/#programs' }, lang)
                }
              >
                {t('home.footerProgramy')}
              </Button>
              <Button
                type="unstyled"
                handleClick={() =>
                  handleMenuLinkClick({ link: '/#food' }, lang)
                }
              >
                {t('home.footerMenu')}
              </Button>
              <Button
                type="unstyled"
                handleClick={() =>
                  handleMenuLinkClick({ link: '/#calculator' }, lang)
                }
              >
                {t('home.footerCena')}
              </Button>
            </div>
          </div>

          <div className={cx(styles.footerCol, styles.contacts)}>
            <h4 className={styles.footerColTitle}>
              {t('home.footerContactCustomers')}
            </h4>
            <p className={styles.contactPhone}>+48 22 600 73 28</p>
            <p className={styles.contactEmail}>info@nutritionpro.pl</p>
          </div>

          <div className={cx(styles.footerCol, styles.hours)}>
            <h4 className={styles.footerColTitle}>Biuro Obsługi Klienta:</h4>
            <p>
              <span style={{ marginRight: '12px' }}>poniedziałek - piątek</span>{' '}
              8:00 - 16:00
            </p>
            <p>
              <span style={{ marginRight: '12px' }}>
                {t('home.footerDays2')}
              </span>{' '}
              {t('home.footerDays2Text')}
            </p>
          </div>
          <div className={cx(styles.footerCol, styles.contacts)}>
            <h4 className={styles.footerColTitle}>Informacje o firmie</h4>
            <p> Nutritionpro sp. z o.o.</p>
            <p> NIP 5273035193</p>
            <p> REGON 524097784</p>
            <p> Rondo Daszyńskiego 2b, 00-843 Warszawa</p>
          </div>
        </div>
      </Container>
    </footer>
  )
}