import React, { useEffect } from 'react'
import { createPortal } from 'react-dom'

// Use a ternary operator to make sure that the document object is defined
const modalRoot =
  typeof document !== `undefined` ? document.querySelector('#modal-root') : null

export const ModalPortal = (props) => {
  const { children } = props

  const element = React.useRef(null)

  if (!element.current) {
    element.current =
      typeof document !== `undefined` ? document.createElement('div') : null
  }

  useEffect(() => {
    if (element) {
      modalRoot.append(element.current)
    }
    return () => {
      if (element) {
        modalRoot.remove(element.current)
      }
    }
  }, [])

  if (element) {
    return createPortal(children, element.current)
  }

  return null
}
